import Vue from 'vue';
const vue = new Vue();

export default function siteMap() {
    return {
        company: {path:'/', title: vue.$dict('Firma')},
        automation: {path:'/automation', title: vue.$dict('Automatyka')},
        products: {path:'/products', title: vue.$dict('Produkty')},
        contact: {path:'/contact', title: vue.$dict('Kontakt')},
    }
}
