<template>
  <p class="companyAddress responsive">
      <span class="name">C&IT Systems Sp. z o.o.</span><br>
      <span class="street">ul. Sienkiewicza 25</span><br>
      <span class="post">05-500 Piaseczno</span><br>
      <span class="country">{{ countryLabel }}</span>
  </p>
</template>

<script>

require("@/assets/css/responsiveText.sass")

export default {
  name: "companyAddress",
  computed: {
    countryLabel: function () {
      return {pl: 'Polska', en: 'Poland', de: 'Polen'}[this.$lang.symbol]
    }
  },
}
</script>

<style scoped>

</style>