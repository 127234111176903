import { render, staticRenderFns } from "./CitCmsLayoutHorizontalMargin.vue?vue&type=template&id=1367c319&scoped=true&"
import script from "./CitCmsLayoutHorizontalMargin.vue?vue&type=script&lang=js&"
export * from "./CitCmsLayoutHorizontalMargin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1367c319",
  null
  
)

export default component.exports