<template>
  <span style="overflow: hidden; position: absolute; top: 0; left: 0; right: 0; bottom: 0">
  <div id="messageContainer" v-if="message">
      {{ message }}
  </div>
  <div id="blend" :style="cssProps">
    <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        width="120.00744mm"
        height="120.00745mm"
        viewBox="0 0 120.00744 120.00745"
        version="1.1"
        id="waitSVG"
        :class="{bigCircle: bigCircle}"
        inkscape:version="0.92.3 (2405546, 2018-03-11)"
        sodipodi:docname="wait.svg">
      <defs
          id="defs2"/>
      <sodipodi:namedview
          id="base"
          pagecolor="#ffffff"
          bordercolor="#666666"
          borderopacity="1.0"
          inkscape:pageopacity="0.0"
          inkscape:pageshadow="2"
          inkscape:zoom="0.49497475"
          inkscape:cx="504.90121"
          inkscape:cy="142.50374"
          inkscape:document-units="mm"
          inkscape:current-layer="layer1"
          showgrid="false"
          inkscape:window-width="1920"
          inkscape:window-height="1057"
          inkscape:window-x="1358"
          inkscape:window-y="-8"
          inkscape:window-maximized="1"
          fit-margin-top="0"
          fit-margin-left="0"
          fit-margin-right="0"
          fit-margin-bottom="0"/>
      <metadata
          id="metadata5">
        <rdf:RDF>
          <cc:Work
              rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type
                rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
            <dc:title></dc:title>
          </cc:Work>
        </rdf:RDF>
      </metadata>
      <g
          inkscape:label="Layer 1"
          inkscape:groupmode="layer"
          id="layer1"
          transform="translate(-40.120541,-126.77678)">
        <g
            id="g4015">
          <circle
              style="opacity:0.03999999;fill:var(--theme-text-color);fill-opacity:1;stroke:none;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
              id="use3955"
              cx="100.12426"
              cy="136.32068"
              r="9.5438995"
              inkscape:transform-center-y="-50.459822"
              inkscape:tile-x0="69.99256"
              inkscape:tile-y0="106.18898"/>
          <circle
              transform="rotate(30)"
              style="opacity:0.12000002;fill:var(--theme-text-color);fill-opacity:1;stroke:none;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
              id="use3957"
              cx="180.1004"
              cy="61.234707"
              r="9.5438995"
              inkscape:transform-center-y="-50.459822"
              inkscape:tile-x0="69.99256"
              inkscape:tile-y0="106.18898"/>
          <circle
              transform="rotate(60)"
              style="opacity:0.2;fill:var(--theme-text-color);fill-opacity:1;stroke:none;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
              id="use3959"
              cx="211.81879"
              cy="-43.77972"
              r="9.5438995"
              inkscape:transform-center-y="-50.459822"
              inkscape:tile-x0="69.99256"
              inkscape:tile-y0="106.18898"/>
          <circle
              transform="rotate(90)"
              style="opacity:0.28000004;fill:var(--theme-text-color);fill-opacity:1;stroke:none;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
              id="use3961"
              cx="186.7805"
              cy="-150.58408"
              r="9.5438995"
              inkscape:transform-center-y="-50.459822"
              inkscape:tile-x0="69.99256"
              inkscape:tile-y0="106.18898"/>
          <circle
              transform="rotate(120)"
              style="opacity:0.36000001;fill:var(--theme-text-color);fill-opacity:1;stroke:none;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
              id="use3963"
              cx="111.69453"
              cy="-230.56023"
              r="9.5438995"
              inkscape:transform-center-y="-50.459822"
              inkscape:tile-x0="69.99256"
              inkscape:tile-y0="106.18898"/>
          <circle
              transform="rotate(150)"
              style="opacity:0.43999999;fill:var(--theme-text-color);fill-opacity:1;stroke:none;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
              id="use3965"
              cx="6.6800976"
              cy="-262.27859"
              r="9.5438995"
              inkscape:transform-center-y="-50.459822"
              inkscape:tile-x0="69.99256"
              inkscape:tile-y0="106.18898"/>
          <circle
              transform="scale(-1)"
              style="opacity:0.51999996;fill:var(--theme-text-color);fill-opacity:1;stroke:none;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
              id="use3967"
              cx="-100.12425"
              cy="-237.24033"
              r="9.5438995"
              inkscape:transform-center-y="-50.459822"
              inkscape:tile-x0="69.99256"
              inkscape:tile-y0="106.18898"/>
          <circle
              transform="rotate(-150)"
              style="opacity:0.6;fill:var(--theme-text-color);fill-opacity:1;stroke:none;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
              id="use3969"
              cx="-180.1004"
              cy="-162.15434"
              r="9.5438995"
              inkscape:transform-center-y="-50.459822"
              inkscape:tile-x0="69.99256"
              inkscape:tile-y0="106.18898"/>
          <circle
              transform="rotate(-120)"
              style="opacity:0.6800001;fill:var(--theme-text-color);fill-opacity:1;stroke:none;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
              id="use3971"
              cx="-211.81879"
              cy="-57.139915"
              r="9.5438995"
              inkscape:transform-center-y="-50.459822"
              inkscape:tile-x0="69.99256"
              inkscape:tile-y0="106.18898"/>
          <circle
              transform="rotate(-90)"
              style="opacity:0.76000001;fill:var(--theme-text-color);fill-opacity:1;stroke:none;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
              id="use3973"
              cx="-186.7805"
              cy="49.66444"
              r="9.5438995"
              inkscape:transform-center-y="-50.459822"
              inkscape:tile-x0="69.99256"
              inkscape:tile-y0="106.18898"/>
          <circle
              transform="rotate(-60)"
              style="opacity:0.83999999;fill:var(--theme-text-color);fill-opacity:1;stroke:none;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
              id="use3975"
              cx="-111.69452"
              cy="129.64058"
              r="9.5438995"
              inkscape:transform-center-y="-50.459822"
              inkscape:tile-x0="69.99256"
              inkscape:tile-y0="106.18898"/>
          <circle
              transform="rotate(-30)"
              style="opacity:0.92000002;fill:var(--theme-text-color);fill-opacity:1;stroke:none;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
              id="use3977"
              cx="-6.6800933"
              cy="161.35896"
              r="9.5438995"
              inkscape:transform-center-y="-50.459822"
              inkscape:tile-x0="69.99256"
              inkscape:tile-y0="106.18898"/>
        </g>
      </g>
    </svg>
  </div>
    </span>
</template>

<script>
export default {
  name: "CitLoadingScreen",

  props: {
    message: {type: String, default: ''},
    bgColor: {type: String, default: 'rgba(0,0,0,0.2)'},
    bgAnimation: {type: Boolean, default: true},
    bigCircle: {type: Boolean, require: false}
  },

  computed: {
    cssProps: function () {
      return {
        '--backgroudColor': this.bgColor,
        '--backgroundAnimationTime': this.bgAnimation ? "1s" : "0s"
      }
    }
  }
}
</script>

<style scoped>
#waitSVG {
  position: absolute;
  opacity: 1;
  top: 35%;
  bottom: 35%;
  left: 35%;
  right: 35%;
  width: 30%;
  height: 30%;
  animation: wait infinite 1s steps(12, end);
}

#waitSVG.bigCircle {
  position: absolute;
  opacity: 1;
  top: 5%;
  bottom: 5%;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 90%;
  animation: wait infinite 1s steps(12, end);
}

#blend {
  z-index: 500;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background-color: var(--backgroudColor);
  animation: appear ease-in forwards var(--backgroundAnimationTime);
}

#messageContainer {
  z-index: 501;
  position: absolute;
  top: 0;
  height: 30vh;
  min-height: 30vh;
  max-height: 30vh;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  text-align: center;
  vertical-align: middle;
  line-height: 30vh;
  font-size: 5vw;
  font-weight: 700;
}

@keyframes wait {
  to {
    transform: rotate(360deg);
  }
}

@keyframes appear {
  to {
    opacity: 1;
  }
}
</style>
