import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state:{
    showLoadingScreen: true
  },
  mutations: {
    SET_SHOW_LOADING_SCREEN(state, payload) {
      state.showLoadingScreen = payload;
    }
  }
});