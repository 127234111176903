<template>
  <cit-cms-layout-horizontal-margin class="topMenu">
      <cit-layout-horizontal stick-horiz>
        <cit-layout-column grow>
          <img :src="`${$env.BASE_URL}img/LogoColorEmblemat.svg`" class="pt-2 pb-2" height="60px"/>&nbsp;
          <img :src="`${$env.BASE_URL}img/LogoColorText.svg`" class="pt-2 pb-2" height="60px"/>
        </cit-layout-column>
        <cit-layout-column shrink>
          <v-menu offset-y
                  origin="center top"
                  transition="scale-transition">
            <template v-slot:activator="{on, attrs}">
              <v-btn circle
                     icon
                     v-bind="attrs"
                     v-on="on">
                <v-icon>mdi-earth</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                  :key="item.countryCode"
                  text
                  v-for="item in flagsDictionary"
              >
                <v-list-item-content @click="$lang.set(item.countryCode)">
                  <gb-flag :code="item.flagCode" size="small"/>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </cit-layout-column>
        <cit-layout-column v-if="!compact" shrink>
           <span style="white-space: nowrap;"><v-btn
                            :key="item.title"
                            :to="item.path"
                            text
                            v-for="item in menuItems"
                        >
                            {{ item.title }}
                        </v-btn>
                        </span>
        </cit-layout-column>
        <cit-layout-column v-if="compact" shrink>
           <v-menu offset-y
                  origin="right top"
                  transition="scale-transition">
            <template v-slot:activator="{on, attrs}">
              <v-btn circle
                     icon
                     v-bind="attrs"
                     v-on="on">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  :key="item.title"
                  :to="item.path"
                  text
                  v-for="item in menuItems"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </cit-layout-column>
      </cit-layout-horizontal>
  </cit-cms-layout-horizontal-margin>
</template>

<script>
import CitLayoutHorizontal from "@/lib/VueLayouts/components/CitLayoutHorizontal";
import CitLayoutColumn from "@/lib/VueLayouts/components/CitLayoutColumn";
import CitCmsLayoutHorizontalMargin from "@/lib/VueCMS/components/CitCmsLayoutHorizontalMargin";

require("@/assets/css/mainLayout.sass")
require("@/assets/css/topMenu.sass")

export default {
  name: "citCmsRendererTopMenu",
  components: {CitCmsLayoutHorizontalMargin, CitLayoutColumn, CitLayoutHorizontal},
  props: {
    menuItems: {
      type: Object, default: () => {
        return {}
      }
    },
    flagsDictionary: {
      type: Array, default: () => {
        return []
      }
    },
    compact: {type: Boolean, default: false}
  }
}
</script>
