<template>
  <cit-layout-horizontal stick-horiz>
    <cit-layout-column class="mainLayout leftPadding"/>
    <cit-layout-column class="mainLayout content">
      <slot/>
    </cit-layout-column>
    <cit-layout-column class="mainLayout rightPadding"/>
  </cit-layout-horizontal>
</template>

<script>
import CitLayoutHorizontal from "@/lib/VueLayouts/components/CitLayoutHorizontal";
import CitLayoutColumn from "@/lib/VueLayouts/components/CitLayoutColumn";

export default {
  name: "CitCmsLayoutHorizontalMargin",
  components: {CitLayoutColumn, CitLayoutHorizontal}
}
</script>

<style scoped>

</style>