import Vue from 'vue'
import vuetify from './plugins/vuetify'
import vuexStore from "./plugins/vuexStore";
import vueRouter from "./plugins/vueRouter";
import App from './App.vue'
import Lang from "./plugins/citLangPlugin";
import VueFlags from "@growthbunker/vueflags"
import VueCookies from "vue-cookies"
import citVueEnv from "./plugins/citVueEnv";
import {publicPath} from '/vue.config';
import VueGtag from "vue-gtag";

Vue.config.productionTip = true
Vue.use(VueFlags, {
    iconPath: `${publicPath}flags/`
});
Vue.use(VueCookies);

Vue.use(VueGtag, {
    config: {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
        params: {
            'send_page_view': false,
            'cookie_expires': 0
        }
    },

});

let vue = new Vue({
    render: h => h(App),
    vuetify,
    store: vuexStore,
    router: vueRouter,
    lang: Lang,
    env: citVueEnv
});
vue.$mount('#app')

// eslint-disable-next-line no-unused-vars
vueRouter.beforeEach((to, from, next) => {
    vue.$store.commit('SET_SHOW_LOADING_SCREEN', true);
    let element = document.getElementById('mainSheet');
    if (element !== null) {
        element.scrollTo(0, 0);
    }
    next();
})

vueRouter.afterEach(() => {
    vue.$store.commit('SET_SHOW_LOADING_SCREEN', false);
})

