<template>
  <td :style="{
      position: 'relative',
      width:width ? width : shrink ? 'auto' : grow ? '100%' : null,
      textAlign: alignLeft ? 'left' : alignRight ? 'right' : alignCenter ? 'center' : null,
      verticalAlign: alignTop ? 'top' : alignBottom ? 'bottom' : alignMiddle ? 'middle' : null
    }" class="citLayoutColumn">
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: "CitLayoutColumn",
  props: {
    alignTop: {required: false, default: false, type: Boolean},
    alignBottom: {required: false, default: false, type: Boolean},
    alignMiddle: {required: false, default: false, type: Boolean},
    alignLeft: {required: false, default: false, type: Boolean},
    alignRight: {required: false, default: false, type: Boolean},
    alignCenter: {required: false, default: false, type: Boolean},
    width: {required: false, default: null, type: String},
    shrink: {required: false, default: false, type: Boolean},
    grow: {required: false, default: false, type: Boolean},
  }
}
</script>

<style scoped>
td.citLayoutColumn {
  border: none;
  padding: 0;
  margin: 0
}
</style>