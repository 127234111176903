var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"citLayoutVertical",class:{
          absolute: _vm.absolute,
          relative: _vm.relative,
          stickTop: _vm.stickTop,
          stickBottom: _vm.stickBottom,
          stickVert: _vm.stickVert,
          center: _vm.center},style:({
          height: _vm.height,
          'top': _vm.absolute ? _vm.top : null,
          'bottom': _vm.absolute ? _vm.bottom : null,
          'margin-top': _vm.absolute ? null : _vm.top,
          'margin-bottom': _vm.absolute ? null : _vm.bottom
})},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }