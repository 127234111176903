import Vue from 'vue'
import vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(vuetify)

const opts = {
   breakpoint: {
       thresholds: {
           xs: 750,
           sm: 960
       }
   }

}

export default new vuetify(opts)