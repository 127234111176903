<template>
  <v-bottom-sheet
      persistent
      v-model="cookiesMsgVisible"
  >
    <v-sheet class="text-center pa-5" color="rgba(0,0,0,0.5)">
      <span class="white--text" v-html="cookiesText[$lang.symbol]"/><br><br>
      <v-btn
          @click="setCookie"
          class="white--text"
          color="blue darken-3"
          elevation="4"
          floating
          large>
        OK
      </v-btn>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: "citCMSCookiesMessage",
  data: function () {
    return {
      cookiesMsgVisible: true,
      cookiesText: {
        'pl': 'Nasza strona internetowa używa plików cookies (tzw. ciasteczka) oraz localstorage w celach statystycznych, reklamowych oraz funkcjonalnych.<br>Dzięki nim możemy indywidualnie dostosować stronę do twoich potrzeb.<br>Możesz zaakceptować pliki cookies lub możesz wyłączyć je w przeglądarce, dzięki czemu nie będą zbierane żadne informacje.',
        'en': 'Our website uses cookies and localstorage for statistical, advertising and functional purposes.<br>Thanks to them, we can customize the site to your needs.<br>You can accept cookies or you can disable them in your browser, so that no information will be collected.'
      }
    }
  },

  created: function () {
    if (this.$cookies.get("cookiesAccepted")) this.cookiesMsgVisible = false;
  },

  methods: {
    setCookie: function () {
      this.$cookies.set("cookiesAccepted", true, '1y');
      this.cookiesMsgVisible = false
    }
  },
}
</script>

<style scoped>

</style>