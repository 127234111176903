import Vue from 'vue';
import Router from 'vue-router';
import {publicPath} from '/vue.config'

Vue.use(Router);
export default new Router({
    mode: 'history',
    base: publicPath,
    routes: [
        {path: '/', name: '', component: () => import('@/components/pages/about')},
        {path: '/automation', name: 'projects', component: () => import('@/components/pages/automation')},
        {path: '/products', name: 'products', component: () => import('@/components/products/products')},
        {path: '/contact', name: 'contact', component: () => import('@/components/pages/contact')},
        {path: '/products/visionit', name: 'visionit', component: () => import('@/components/pages/productsVisionIt')},
        {path: '/products/inloginoee', name: 'inloginoee', component: () => import('@/components/pages/productsInLoginOee')},
        {path: '*', component: () => import('@/components/pages/about')}
    ],
})
