import Vue from 'vue';
import dictionary from "@/dictionary";

class CitLang {
    constructor() {
        this.pl = true;
        this.en = false;
        this.de = false;
        this.es = false;
        this.fr = false;
        this.ru = false;
        this.symbol = 'pl';
        if (localStorage.citLangSymbol) {
            this.set(localStorage.citLangSymbol);
        } else {
            this.set('pl');
        }
        return Vue.observable(this)
    }

    set(language) {
        this.resetAllFlags();
        switch (language) {
            case 'pl':
                this.symbol = 'pl';
                this.pl = true;
                break;

            case 'en':
                this.symbol = 'en';
                this.en = true;
                break;

            case 'de':
                this.symbol = 'de';
                this.de = true;
                break;

            case 'es':
                this.symbol = 'es';
                this.es = true;
                break;

            case 'fr':
                this.symbol = 'fr';
                this.fr = true;
                break;

            case 'ru':
                this.symbol = 'ru';
                this.ru = true;
                break;

            default:
                this.symbol = 'pl';
                this.pl = true;
        }
        localStorage.citLangSymbol = this.symbol;
    }

    resetAllFlags() {
        this.pl = false;
        this.en = false;
        this.de = false;
        this.es = false;
        this.fr = false;
        this.ru = false;
    }

    dict(text = '') {
        try {
            return dictionary[text][this.symbol]
        } catch (e) {
            //console.trace(`Translation for ${text} not found in ${this.symbol} dictionary table.`);
            return "###"
        }
    }
}

export default {
    install(Vue) {
        let lang = new CitLang();
        Vue.prototype.$lang = lang;
        Vue.prototype.$dict = (text) => {
            return lang.dict(text)
        };
    }
}