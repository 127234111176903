<template>
  <p class="companyContact responsive">
    <span class="call">tel. +48 605 475 777</span><br>
    <span class="email">e-mail: <a :href="'mailto:biuro@citsystems.pl'" class="link">biuro@citsystems.pl</a></span><br>
    <span class="vatin">NIP: PL1231452754</span>
  </p>
</template>

<script>
export default {
  name: "companyContact"
}
</script>

<style scoped>

</style>