<template>
  <tr class="citLayoutRow"
      :style="{
      height:height ? height : shrink ? 'auto' : grow ? '100%' : 'auto',
      maxHeight: maxHeight,
      minHeight: minHeight}"
  >
    <td style="position: relative" :style="{
      textAlign: alignLeft ? 'left' : alignRight ? 'right' : alignCenter ? 'center' : null,
      verticalAlign: alignTop ? 'top' : alignBottom ? 'bottom' : alignMiddle ? 'middle' : null,
      ...cStyle}"
        class="citLayoutColumn" :class="cClass">
      <slot></slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: "CitLayoutRow",
  props: {
    cClass: {required: false, type: Object},
    cStyle: {required: false, type: Object},
    alignTop: {required: false, default: false, type: Boolean},
    alignBottom: {required: false, default: false, type: Boolean},
    alignMiddle: {required: false, default: false, type: Boolean},
    alignLeft: {required: false, default: false, type: Boolean},
    alignRight: {required: false, default: false, type: Boolean},
    alignCenter: {required: false, default: false, type: Boolean},
    height: {required: false, default: null, type: String},
    maxHeight: {required: false, default: null, type: String},
    minHeight: {required: false, default: null, type: String},
    shrink: {required: false, default: false, type: Boolean},
    grow: {required: false, default: false, type: Boolean},
  }
}
</script>

<style scoped>
tr {
  border: none;
  padding: 0;
  margin: 0
}

td {
  border: none;
  padding: 0;
  margin: 0
}
</style>