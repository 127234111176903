<template>
  <table :style="{
            height: height,
            'top': absolute ? top : null,
            'bottom': absolute ? bottom : null,
            'margin-top': absolute ? null : top,
            'margin-bottom': absolute ? null : bottom
  }"
         :class="{
            absolute: absolute,
            relative: relative,
            stickTop: stickTop,
            stickBottom: stickBottom,
            stickVert: stickVert,
            center: center}"
         class="citLayoutVertical">
      <slot></slot>
  </table>
</template>

<script>
export default {
  name: "CitLayoutVertical",
  props: {
    tClass: {required: false, type: Object},
    absolute: {required: false, default: false, type: Boolean},
    relative: {required: false, default: false, type: Boolean},
    height: {default: undefined, type: String},
    top: {default: undefined, type: String},
    bottom: {default: undefined, type: String},
    stickTop: {required: false, default: false, type: Boolean},
    stickBottom: {required: false, default: false, type: Boolean},
    stickVert: {required: false, default: false, type: Boolean},
    center: {required: false, default: false, type: Boolean},
  }
}
</script>

<style scoped>
table.citLayoutVertical {
  width: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  border: none;
  border-collapse: collapse
}

table.citLayoutVertical.absolute.stickTop {
  top: 0;
}

table.citLayoutVertical.absolute.stickBottom {
  bottom: 0;
}

table.citLayoutVertical.absolute.stickVert {
  top: 0;
  bottom: 0;
}

table.citLayoutVertical.absolute.center {
  top: 50%;
  transform: translateY(-50%)
}

table.citLayoutVertical.stickTop {
  margin-top: 0
}

table.citLayoutVertical.stickBottom {
  margin-bottom: 0
}

table.citLayoutVertical.stickVert {
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
}

table.citLayoutVertical.center {
  margin-top: auto;
  margin-bottom: auto;
}

</style>