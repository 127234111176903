<template>
  <v-app>
    <v-app-bar absolute
               app
               height="72px"
               hide-on-scroll
               scroll-target="#mainSheet"
    >
      <cit-cms-renderer-top-menu :menu-items="menuItems"
                                 :flags-dictionary="flagsDictionary"
                                 inner-width="1200px"
                                 :compact="$vuetify.breakpoint.smAndDown"
      ></cit-cms-renderer-top-menu>
    </v-app-bar>
    <v-sheet
        class="overflow-y-auto"
        id="mainSheet"
        max-height="100vh"
        min-height="100vh"
    >
      <cit-loading-screen v-if="showLoadingScreen"/>
      <cit-layout-vertical stick-vert height="100vh">
        <cit-layout-row grow>
          <router-view></router-view>
        </cit-layout-row>
        <cit-layout-row shrink>
          <cit-renderer-footer :compact="$vuetify.breakpoint.xsOnly"></cit-renderer-footer>
        </cit-layout-row>
      </cit-layout-vertical>

    </v-sheet>
    <cit-c-m-s-cookies-message/>
  </v-app>
</template>
<script>

import citCmsRendererTopMenu from "@/lib/VueCMS/components/citCmsRendererTopMenu";
import CitCMSCookiesMessage from "@/lib/VueCMS/components/citCmsCookiesMessage";
import CitRendererFooter from "@/lib/VueCMS/components/citCmsRendererFooter";
import siteMap from "@/components/maps/siteMap";
import CitLayoutVertical from "@/lib/VueLayouts/components/CitLayoutVertical";
import CitLayoutRow from "@/lib/VueLayouts/components/CitLayoutRow";
import CitLoadingScreen from "@/lib/VueComponents/components/CitLoadingScreen";

export default {
  name: "App",
  components: {
    CitLoadingScreen,
    CitLayoutRow,
    CitLayoutVertical,
    CitRendererFooter, CitCMSCookiesMessage, citCmsRendererTopMenu,
  },
  data: () => {
    return {
      flagsDictionary: [
        {countryCode: 'pl', flagCode: 'pl'},
        {countryCode: 'en', flagCode: 'gb'},
        /*{countryCode: 'de', flagCode: 'de'},*/
      ],
    }
  },

  created: function () {
    this.$lang.set(navigator.language.slice(0, 2));
  },

  watch: {
    '$route.query.uid': function () {
      this.$gtag.set({'user_id': this.$route.query.uid});
    }
  },

  computed: {
    menuItems: function () {
      return siteMap()
    },
    showLoadingScreen() {
      return this.$store.state.showLoadingScreen;
    }
  },
}

</script>

<style>
.v-btn__content {
  font-weight: bold;
  text-transform: none;
}


#menu li {
  display: inline-block;
  padding: 23px 15px;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 20px;
}

.v-toolbar__content {
  margin: 0;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  line-height: 1;
}

#app {
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
}



.contentContainer {
  background-color: #E3F2FD;
  min-height: 100vh;
}

.footerLinkDiv {
  padding: 3px 0;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>