var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"citLayoutHorizontal",class:{
        absolute: _vm.absolute,
        relative: _vm.relative,
        stickRight: _vm.stickRight,
        stickLeft: _vm.stickLeft,
        stickHoriz: _vm.stickHoriz,
        center: _vm.center},style:({
        'width': _vm.width,
        'left': _vm.absolute ? _vm.left : null,
        'right': _vm.absolute ? _vm.right : null,
        'margin-left': _vm.absolute ? null : _vm.left,
        'margin-right': _vm.absolute ? null : _vm.right
})},[_c('tr',{staticClass:"citLayoutRow",class:_vm.rClass},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }