<template>
  <cit-layout-vertical>
    <cit-layout-row height="8px" class="footerShadow"></cit-layout-row>
    <cit-layout-row shrink>
      <cit-cms-layout-horizontal-margin class="pageFooter">
        <cit-layout-vertical stick-top>
          <cit-layout-row>
            <cit-layout-horizontal width="100%" class="pt-3 pb-5">
              <cit-layout-column :width="!compact ? '33%' : '50%'" alignTop>
                <h1 class="responsive">>> {{ $dict('Adres') }}</h1>
                <company-address></company-address>
              </cit-layout-column>
              <cit-layout-column :width="!compact ? '33%' : '50%'" alignTop
                                 :class="{textCenter: $vuetify.breakpoint.smAndUp, textRight: $vuetify.breakpoint.xs}">
                <h1 class="responsive">>> {{ $dict('Kontakt') }}</h1>
                <company-contact></company-contact>
              </cit-layout-column>
              <cit-layout-column width="auto" v-if="!compact" alignTop class="textRight">
                <h1 class="responsive">>> {{ $dict('Mapa strony') }}</h1>
                <p class="responsive">
              <span :key="key" class="footerLinkDiv responsive" v-for="(item, key) in siteMap">
              <a :href="item.path" class="link">{{ item.title }}</a><br>
            </span>
                </p>
              </cit-layout-column>
            </cit-layout-horizontal>
          </cit-layout-row>
          <cit-layout-row width="auto" v-if="compact" alignTop class="textCenter">
            <h1 class="responsive">>> {{ $dict('Mapa strony') }}</h1>
            <p class="responsive">
          <span :key="key" class="footerLinkDiv responsive" v-for="(item, key, index) in siteMap">
                <a :href="item.path" class="link">{{ item.title }}</a>
                <template v-if="index + 1 < Object.keys(siteMap).length"> || </template>
              </span>
            </p>
          </cit-layout-row>
        </cit-layout-vertical>
      </cit-cms-layout-horizontal-margin>
    </cit-layout-row>
  </cit-layout-vertical>
</template>

<script>

import CompanyAddress from "@/components/misc/companyAddress";
import CompanyContact from "@/components/misc/companyContact";
import SiteMap from "@/components/maps/siteMap";
import CitLayoutHorizontal from "@/lib/VueLayouts/components/CitLayoutHorizontal";
import CitLayoutColumn from "@/lib/VueLayouts/components/CitLayoutColumn";
import CitLayoutRow from "@/lib/VueLayouts/components/CitLayoutRow";
import CitLayoutVertical from "@/lib/VueLayouts/components/CitLayoutVertical";
import CitCmsLayoutHorizontalMargin from "@/lib/VueCMS/components/CitCmsLayoutHorizontalMargin";

require('@/assets/css/mainLayout.sass');
require('@/assets/css/pageFooter.sass');
require('@/assets/css/responsiveText.sass');

export default {
  name: "citFooter",
  components: {
    CitCmsLayoutHorizontalMargin,
    CitLayoutVertical, CitLayoutRow, CitLayoutColumn, CitLayoutHorizontal, CompanyContact, CompanyAddress
  },
  props: {
    compact: {type: Boolean, default: false}
  },
  computed: {
    siteMap() {
      return SiteMap();
    }
  }
}
</script>