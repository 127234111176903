<template>
  <table :style="{
          'width': width,
          'left': absolute ? left : null,
          'right': absolute ? right : null,
          'margin-left': absolute ? null : left,
          'margin-right': absolute ? null : right
  }"
       :class="{
          absolute: absolute,
          relative: relative,
          stickRight: stickRight,
          stickLeft: stickLeft,
          stickHoriz: stickHoriz,
          center: center}"
       class="citLayoutHorizontal">
      <tr class="citLayoutRow" :class="rClass">
        <slot></slot>
      </tr>
    </table>
</template>

<script>
export default {
  name: "CitLayoutHorizontal",
  props: {
    tClass: {required: false, type: Object},
    rClass: {required: false, type: Object},
    absolute: {required: false, default: false, type: Boolean},
    relative: {required: false, default: false, type: Boolean},
    width: {default: undefined, type: String},
    stickRight: {required: false, default: false, type: Boolean},
    stickLeft: {required: false, default: false, type: Boolean},
    stickHoriz: {required: false, default: false, type: Boolean},
    center: {required: false, default: false, type: Boolean},
    left: {default: undefined, type: String},
    right: {default: undefined, type: String},
  }
}
</script>

<style scoped>
table.citLayoutHorizontal {
  height: 100%;
  top: 0;
  bottom: 0;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  border: none;
  border-collapse: collapse
}

table.citLayoutHorizontal.absolute.stickRight {
  right: 0;
}

table.citLayoutHorizontal.absolute.stickLeft {
  left: 0;
}

table.citLayoutHorizontal.absolute.stickHoriz {
  left: 0;
  right: 0;
}

table.citLayoutHorizontal.absolute.center {
  left: 50%;
  transform: translateX(-50%)
}

table.citLayoutHorizontal.stickLeft {
  margin-left: 0
}

table.citLayoutHorizontal.stickRight {
  margin-right: 0
}

table.citLayoutHorizontal.stickHoriz {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

table.citLayoutHorizontal.center {
  margin-left: auto;
  margin-right: auto;
}

table.citLayoutHorizontal > tr {
  padding: 0;
  margin: 0
}


</style>